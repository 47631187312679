<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t("customerMsgs.customers") }}
              </h4>
            </div>

            <router-link :to="{ name: 'add-customer' }" class="btn btn-primary">
              {{ $t("customerMsgs.addCustomer") }}
            </router-link>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="mm-search-bar col-md-6 mb-3">
                <div class="searchbox">
                  <a class="search-link" href="#"
                    ><i class="fas fa-search"></i
                  ></a>
                  <input
                    type="text"
                    class="form-control search-input"
                    v-model="searchTerm"
                    :placeholder="`${$t('customerMsgs.searchCustomers')}...`"
                    @input="onSearch"
                  />
                </div>
              </div>

              <b-table
                :fields="tableColumns"
                :items="allCustomers"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                no-local-sorting
                @sort-changed="onSortChange"
              >
                <template #cell(name)="data">
                  <strong> {{ data.item.name }} </strong>
                </template>
                <template #cell(customer_number)="data">
                  <strong> {{ data.item.customer_number }} </strong>
                </template>
                <template #cell(revenue_factor)="data">
                  {{
                    data.item.revenue_factor !== 1
                      ? $n(data.item.revenue_factor, "number", "de-DE")
                      : "-"
                  }}
                </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data">
                  <a
                    href="#"
                    @click.prevent="openCostCentersModal(data.item)"
                    class="mr-2"
                    tabindex="0"
                    v-b-popover.hover.top
                    :title="$t('costCenterMsgs.manageCostCenters')"
                  >
                    <i class="far fa-building text-light" />
                  </a>

                  <router-link
                    :to="{
                      name: 'edit-customer',
                      params: { id: data.item.id },
                    }"
                    class="svg-icon mr-2"
                    v-b-popover.hover.top
                    :title="$t('edit')"
                  >
                    <i class="far fa-edit text-info" />
                  </router-link>
                  <a
                    href="#"
                    @click.prevent="confirmDeleteCustomer(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('remove')"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                :value="currentPage"
                :total-rows="totalCustomers"
                :per-page="pageSize"
                @change="onPageChange"
                align="right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <cost-center-list-modal
      :showModal.sync="modals.showCostCentersModal"
      :customerId="modals.selectedCustomerId"
      :customerName="modals.selectedCustomerName"
      @close="hideCostCentersModal"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
import { DEFAULT_PAGE_SIZE } from "../../common/constants";
import CostCenterListModal from "./CostCenters/CostCenterListModal";

export default {
  name: "CustomerList",

  data() {
    return {
      allCustomers: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalCustomers: 0,
      isLoading: false,
      ordering: "",
      searchTerm: "",

      modals: {
        selectedCustomerId: null,
        selectedCustomerName: null,
        showCostCentersModal: false,
      },
    };
  },

  components: {
    CostCenterListModal,
  },

  methods: {
    ...mapActions(["getAllCustomers", "deleteCustomer"]),

    async fetchAndSetCustomers(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllCustomers({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allCustomers = response.data.results;
      this.currentPage = pageNum;
      this.totalCustomers = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchAndSetCustomers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchAndSetCustomers();
    },

    onSearch() {
      this.debouncedSearchCustomers(this);
    },

    debouncedSearchCustomers: debounce((vm) => {
      vm.fetchAndSetCustomers();
    }, 500),

    async confirmDeleteCustomer(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("customerMsgs.deleteConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteCustomer(id);
          const refreshPage =
            this.allCustomers.length > 1
              ? this.currentPage
              : this.currentPage - 1;
          this.fetchAndSetCustomers(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.deletedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },

    openCostCentersModal(customer) {
      this.modals.selectedCustomerId = customer.id;
      this.modals.selectedCustomerName = customer.name;
      this.modals.showCostCentersModal = true;
    },

    hideCostCentersModal() {
      this.modals.showCostCentersModal = false;
      this.modals.selectedCustomerId = null;
      this.modals.selectedCustomerName = null;
    },
  },

  async mounted() {
    this.fetchAndSetCustomers();
  },

  computed: {
    tableColumns() {
      return [
        { key: "customer_number", label: this.$t("customerMsgs.custNum"), sortable: true },
        { key: "name", label: this.$t("name"), sortable: true },
        { key: "revenue_factor", label: this.$t("customerMsgs.revFactor"), sortable: true },
        { key: "street", label: this.$t("customerMsgs.street"), sortable: true },
        { key: "post_code", label: this.$t("customerMsgs.postalCode"), sortable: true },
        { key: "town", label: this.$t("customerMsgs.city"), sortable: true },
        {
          key: "actions",
          tdClass: "text-right",
          thStyle: { minWidth: "100px" },
        },
      ];
    },
  },
};
</script>
