<template>
  <div class="container-fluid">
    <b-modal
      ref="customer-cost-centers-modal"
      size="lg"
      :title="$t('costCenterMsgs.costCenters')"
      scrollable
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="col-md-12 text-right">
            <a
              href="#"
              class="btn btn-sm btn-primary"
              @click.prevent="openPostCostCenterModal()"
            >
              {{ $t("costCenterMsgs.addCostCenter") }}
            </a>
          </div>
          <div class="mt-3">
            <div class="table-responsive">
              <b-table
                :fields="tableColumns"
                :items="allCostCenters"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
              >
                <template #cell(center_number)="data">
                  <strong> {{ data.item.center_number }} </strong>
                </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data">
                  <a
                    href="#"
                    @click.prevent="openPostCostCenterModal(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('edit')"
                    class="mr-2"
                  >
                    <i class="far fa-edit text-info" />
                  </a>
                  <a
                    href="#"
                    @click.prevent="confirmDeleteCostCenter(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('remove')"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                v-if="totalCostCenters > pageSize"
                :value="currentPage"
                :total-rows="totalCostCenters"
                :per-page="pageSize"
                @change="onPageChange"
                align="center"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <post-cost-center-modal
      :showModal.sync="modals.showPostCostCenterModal"
      :customerId="customerId"
      :customerName="customerName"
      :costCenterId="modals.selectedCostCenterId"
      @close="hidePostCostCenterModal"
      @closeUpdate="hideUpdatePostCostCenterModal"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { DEFAULT_PAGE_SIZE } from "../../../common/constants";
import PostCostCenterModal from "./PostCostCenterModal";

export default {
  name: "CostCenterListModal",

  props: {
    showModal: Boolean,
    customerId: Number,
    customerName: String,
  },

  components: { PostCostCenterModal },

  data() {
    return {
      allCostCenters: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalCostCenters: 0,
      isLoading: false,

      modals: {
        showPostCostCenterModal: false,
        selectedCostCenterId: null,
      },
    };
  },

  methods: {
    ...mapActions(["getAllCostCenters", "deleteCostCenter"]),

    hideModal() {
      this.$emit("close");
      this.allCostCenters = [];
      this.currentPage = 1;
      this.totalCostCenters = 0;
      this.isLoading = false;
    },

    async fetchCostCenters(pageNum = 1, params = {}) {
      this.isLoading = true;

      try {
        const response = await this.getAllCostCenters({
          limit: this.pageSize,
          offset: (pageNum - 1) * this.pageSize,
          customer: this.customerId,
          ...params,
        });
        this.allCostCenters = response.data.results;
        this.currentPage = pageNum;
        this.totalCostCenters = response.data.count;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchCostCenters(pageNum);
    },

    onSearch(params) {
      this.fetchCostCenters(
        1,
        params.searchTerm ? { search: params.searchTerm } : {}
      );
    },

    async confirmDeleteCostCenter(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("costCenterMsgs.deleteConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteCostCenter(id);
          const refreshPage =
            this.allCostCenters.length > 1
              ? this.currentPage
              : this.currentPage - 1;
          this.fetchCostCenters(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.deletedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    openPostCostCenterModal(costCenterId = null) {
      this.modals.selectedCostCenterId = costCenterId;
      this.modals.showPostCostCenterModal = true;
    },

    hidePostCostCenterModal() {
      this.modals.showPostCostCenterModal = false;
      this.modals.selectedCostCenterId = null;
    },

    hideUpdatePostCostCenterModal() {
      this.hidePostCostCenterModal();
      this.fetchCostCenters(this.currentPage);
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    tableColumns() {
      return [
        { key: "center_number", label: this.$t("costCenterMsgs.centerNum") },
        { key: "name", label: this.$t("name") },
        { key: "description", label: this.$t("description") },
        {
          key: "actions",
          tdClass: "text-right",
          thStyle: { minWidth: "80px" },
        },
      ];
    },
  },

  watch: {
    showModal(value) {
      if (value && this.customerId) {
        this.fetchCostCenters();
        this.$refs["customer-cost-centers-modal"].show();
      }
    },
  },
};
</script>