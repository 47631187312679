<template>
  <div class="container-fluid">
    <b-modal
      ref="post-cost-center-modal"
      size="lg"
      :title="
        costCenterId
          ? $t('costCenterMsgs.editCostCenter')
          : $t('costCenterMsgs.addCostCenter')
      "
      scrollable
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="customer">{{
                    $t("customerMsgs.customer")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="customer"
                    :value="customerName"
                    disabled
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="centerNum">{{
                    $t("costCenterMsgs.centerNum")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="centerNum"
                    :placeholder="$t('costCenterMsgs.centerNum')"
                    v-model="centerNum"
                    required
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="name">{{ $t("name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    :placeholder="$t('costCenterMsgs.namePlaceholder')"
                    v-model="name"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="description">{{ $t("description") }}</label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="description"
                    :placeholder="$t('optDescPlaceholder')"
                    v-model="description"
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading"
                  style="min-width: 100px"
                >
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{
                    costCenterId ? $t("update") : $t("add")
                  }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "PostCostCenterModal",

  props: {
    showModal: Boolean,
    customerId: Number,
    customerName: String,
    costCenterId: Number,
  },

  data() {
    return {
      name: null,
      description: null,
      centerNum: null,
      center: null,

      isLoading: false,
    };
  },

  methods: {
    ...mapActions([
      "getCostCenter",
      "createCostCenter",
      "updateCostCenter",
      "getCustomer",
    ]),

    resetData() {
      this.name = this.description = null;
      this.centerNum = null;
      this.isLoading = false;
    },

    hideModal() {
      this.$emit("close");
      this.resetData();
    },

    hideModalManual() {
      this.$refs["post-cost-center-modal"].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs["post-cost-center-modal"].hide();
      this.$emit("closeUpdate", this.center.data.data);
      this.resetData();
    },

    async fetchCostCenter() {
      this.isLoading = true;

      try {
        const center = (await this.getCostCenter(this.costCenterId)).data;
        this.name = center.name;
        this.centerNum = center.center_number;
        this.description = center.description;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async onSubmit() {
      // TODO: Add validations.

      this.isLoading = true;

      try {
        const centerData = {
          customer: this.customerId,
          name: this.name,
          center_number: this.centerNum,
          description: this.description,
        };
        if (this.costCenterId) {
          this.center = await this.updateCostCenter({
            id: this.costCenterId,
            data: centerData,
          });
          this.makeToast(
            "success",
            this.$t("costCenterMsgs.costCenterUpdated")
          );
        } else {
          this.center = await this.createCostCenter(centerData);
          this.makeToast("success", this.$t("costCenterMsgs.costCenterAdded"));
        }
        this.isLoading = false;
        this.hideWithUpdateModal();
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {},

  watch: {
    showModal(value) {
      if (value && this.customerId) {
        if (this.costCenterId) {
          this.fetchCostCenter();
        }
        this.$refs["post-cost-center-modal"].show();
      }
    },
  },
};
</script>
